.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
&::-moz-focus-inner {
  border: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
}

header {
  margin: auto;
}

section {
  max-width: 900px;
  margin: auto;
  text-align: center;
}

section img {
  width: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.icon {
  max-width: 4vh;
}

ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}

li {
  display: inline;
  margin: 14px;
}

nav li {
  /* background-color: #333; */
  /* color: white;
  padding: 16px;
  margin: 16px 0; */
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
  margin: auto;
}

button img {
  width: 80px;
  height: 40px;
  cursor: pointer;
}

button {
  width: 60px;
  cursor: pointer;
}

button:active {
  outline: none;
}

button:focus {
  outline: none;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: white;
  max-width: 25%;
  text-align: center;
  padding: 1%;
  border-radius: 6px;
  font-family: schoolbellregular;
  opacity: 0;

  bottom: 5%;
  left: 2%;


  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: .85;
  transition: opacity  0s linear 1.5s;
}

h3 {
  font-family: schoolbellregular, Arial, sans-serif;
  font-size: 2rem;
  line-height: 3.1rem;
}

p {
  font-family: schoolbellregular, Arial, sans-serif;
  font-weight: lighter;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

@font-face {
    font-family: 'schoolbellregular';
    src: url('Schoolbell-webfont.eot');
    src: url('Schoolbell-webfont.eot?#iefix') format('embedded-opentype'),
         /* url('Schoolbell-webfont.woff2') format('woff2'), */
         url('Schoolbell-webfont.woff') format('woff'),
         url('Schoolbell-webfont.ttf') format('truetype'),
         url('Schoolbell-webfont.svg#schoolbellregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@media screen and (max-device-width: 900px) {
  h1 {
    font-size: 6rem;
  }
  section img {
    max-width: 98%;
  }
  header {
    margin: 5vh;
  }
  section {
    margin: 0;
  }
  .icon {
    max-width: 3vh;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: white;
    max-width: 65%;
    text-align: center;
    padding: 1%;
    border-radius: 6px;
    font-family: schoolbellregular;
    opacity: 0;

    bottom: 10%;
    left: 2%;


    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
}
